// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {adHW5b9Dz: {hover: true}};

const serializationHash = "framer-ljgk9"

const variantClassNames = {adHW5b9Dz: "framer-v-2cgvin"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, title, width, ...props}) => { return {...props, diPgoN23n: link ?? props.diPgoN23n, Ng8ZBDOhG: title ?? props.Ng8ZBDOhG ?? "Tertiary Button"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Ng8ZBDOhG, diPgoN23n, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "adHW5b9Dz", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={diPgoN23n}><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-2cgvin", className, classNames)} framer-1tzgoz`} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"adHW5b9Dz"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-e21d78c6-9a46-4cbd-aadc-ebd33ec712f0, rgb(245, 245, 247))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-b89bd1a0-40e7-4388-a0fb-65b3a400a527, rgb(251, 251, 251))", borderBottomLeftRadius: 6, borderBottomRightRadius: 6, borderTopLeftRadius: 6, borderTopRightRadius: 6, ...style}} variants={{"adHW5b9Dz-hover": {"--border-color": "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(229, 77, 46))", backgroundColor: "rgb(255, 203, 191)"}}} {...addPropertyOverrides({"adHW5b9Dz-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "RlM7U2F0b3NoaS1ib2xk", "--framer-font-family": "\"Satoshi\", \"Satoshi Placeholder\", sans-serif", "--framer-font-size": "14px", "--framer-font-weight": "700", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(21, 21, 21)))", "--framer-text-transform": "uppercase"}}>Tertiary Button</motion.p></React.Fragment>} className={"framer-1dyw82j"} data-framer-name={"SUBSCRIBE FOR $0.3/WEEK"} fonts={["FS;Satoshi-bold"]} layoutDependency={layoutDependency} layoutId={"xSLtBB1aG"} style={{"--extracted-r6o4lv": "var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(21, 21, 21))"}} text={Ng8ZBDOhG} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ljgk9.framer-1tzgoz, .framer-ljgk9 .framer-1tzgoz { display: block; }", ".framer-ljgk9.framer-2cgvin { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 10px 15px 10px 15px; position: relative; text-decoration: none; width: min-content; }", ".framer-ljgk9 .framer-1dyw82j { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ljgk9.framer-2cgvin { gap: 0px; } .framer-ljgk9.framer-2cgvin > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-ljgk9.framer-2cgvin > :first-child { margin-left: 0px; } .framer-ljgk9.framer-2cgvin > :last-child { margin-right: 0px; } }", ".framer-ljgk9[data-border=\"true\"]::after, .framer-ljgk9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 37
 * @framerIntrinsicWidth 157
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"gzSwa_QLW":{"layout":["auto","auto"]}}}
 * @framerVariables {"Ng8ZBDOhG":"title","diPgoN23n":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKTeSzQ8cS: React.ComponentType<Props> = withCSS(Component, css, "framer-ljgk9") as typeof Component;
export default FramerKTeSzQ8cS;

FramerKTeSzQ8cS.displayName = "Button/Tertiary";

FramerKTeSzQ8cS.defaultProps = {height: 37, width: 157};

addPropertyControls(FramerKTeSzQ8cS, {Ng8ZBDOhG: {defaultValue: "Tertiary Button", displayTextArea: false, title: "Title", type: ControlType.String}, diPgoN23n: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerKTeSzQ8cS, [{explicitInter: true, fonts: [{family: "Satoshi", source: "fontshare", style: "normal", url: "https://framerusercontent.com/third-party-assets/fontshare/wf/LAFFD4SDUCDVQEXFPDC7C53EQ4ZELWQI/PXCT3G6LO6ICM5I3NTYENYPWJAECAWDD/GHM6WVH6MILNYOOCXHXB5GTSGNTMGXZR.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})